<template>
  <v-row style="height: 100%">
    <MainModal
        :main="{ component: 'Building', title: 'Будинки' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_building)"
        :modal="show_building_dialog"
        @updateItemModal="buildingUpdateItemModal"
    />
    <v-col cols="12" md="12" class="pt-2 pb-2">
      <v-card tile elevation="1" style="border-left: 3px solid #fafafa; height: 100%; position: relative;">
        <v-toolbar elevation="0" class="pa-0">
          <v-toolbar-title>
            Список будинків
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="grey lighten-4 mr-2" @click="table_view = !table_view">
            <v-icon>
              {{ table_view ? 'mdi-view-list' : 'mdi-format-list-group' }}
            </v-icon>
          </v-btn>
          <YouTubeButton
              button-class="grey lighten-4 mr-2"
              icon-color="error lighten-1"
              :icon-size="26"
              href="https://youtu.be/LFousBkvF38"
          />
          <v-tooltip bottom color="success">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="openBuildingCreateDialog"
                     v-bind="attrs"
                     v-on="on"
                     class="grey lighten-4 mr-1">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Створити новий будинок</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text class="pt-0 pb-4">
          <div class="filter-wrapper" style="display: flex">
            <div class="filter-left-side" style="flex: 1; display: flex">
              <div style="flex: 1; margin-right: 10px">
                <AddressElementSelect v-model="cityId"
                                      label="Населений пункт" filled
                                      select_type="city"
                                      :auto_fill_when_first="true"
                                      :style_="'z-index: 40'"
                                      @selectChanged="cityChange"
                />
              </div>
              <div style="flex: 1">
                <AddressElementSelect :parent_id="cityId" v-model="streetId"
                                      label="Вулиця(-ки)" filled
                                      select_type="street" use_parent_id
                                      :style_="'z-index: 40'"
                                      :disabled="!cityId"
                                      @selectChanged="streetChange"
                />
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text class="pt-0 mt-2" v-if="!table_view">
          <v-row v-if="buildings.length">
            <v-col cols="12" sm="6" md="4" lg="4" xl="3" v-for="(item, index) in filtered_items" :key="index">
              <v-card
                  shaped
                  class="building-card"
                  @click.stop="$router.push({ name: 'BuildView', params: { 'id': item.id } })">
                <div class="background bg-1"></div>
                <div class="background bg-2"></div>
                <v-card-title class="grey--text text--darken-2">
                  <div style="display: flex; align-items: center; width: 100%; z-index: 20">
                    <div style="flex: 1 1; font-size: 1.06em" class="card-address">
                      {{ item.address }}
                    </div>
                    <div style="width: 46px; text-align: right;">
                      <v-avatar size="40" :color="getBuildingIcon(item.building_type, 'color')">
                        <v-icon size="26" dark>
                          {{ getBuildingIcon(item.building_type, 'icon') }}
                        </v-icon>
                      </v-avatar>
                    </div>
                  </div>
                </v-card-title>
                <v-divider style="z-index: 20"></v-divider>
                <v-card-text>
                  <v-list class="pt-0">
                    <v-list-item class="px-0" style="min-height: 30px; z-index: 40">
                      <v-list-item-action class="text-left caption grey--text text--darken-1 my-1">
                        Населений пункт
                      </v-list-item-action>
                      <v-list-item-content class="py-1">
                        <v-list-item-title class="text-right font-weight-medium subtitle-2">
                          {{ item.city_name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="px-0" style="min-height: 34px; z-index: 40;">
                      <v-list-item-action class="text-left caption grey--text text--darken-1 my-1">
                        К-сть квартир
                      </v-list-item-action>
                      <v-list-item-content class="py-1">
                        <v-list-item-title class="text-right font-weight-medium subtitle-2">
                          {{ item.flats || 0 }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <h3 class="no-data-text">
                Не створено жодного будинку. Натисніть + для створення
              </h3>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pt-0 mt-2" v-else>
          <v-data-table
              :headers="buildingHeader"
              :items="filtered_items"
              no-data-text="Не створено жодного будинку. Натистніть + для створення"
              :footer-props="{
                          showFirstLastPage: true,
                          itemsPerPageText: 'Рядків на сторінку',
                          itemsPerPageOptions: [15, 25, 40, -1],

                        }"
              :hide-default-footer="true"
              class="custom-table custom-table-1"
              @click:row="openBuildingDialog"
          >
            <template v-slot:item.icon="{ item }">
              <v-icon :color="getBuildingIcon(item.building_type, 'color')">
                {{ getBuildingIcon(item.building_type, 'icon') }}
              </v-icon>
            </template>
            <template v-slot:item.city_name="{ item }">
              {{ `${cityTypesShort[item.city_type]}${item.city_name}` }}
            </template>
            <template v-slot:item.street_name="{ item }">
              {{ `${streetTypesShort[item.street_type]} ${item.street_name}` }}
            </template>
            <template v-slot:item.building_type="{ item }">
              {{ `${buildingTypes[item.building_type]}` }}
            </template>
            <template v-slot:item.create_date="{ item }">
              {{ item.create_date | formatDate }}
            </template>
          </v-data-table>
        </v-card-text>
        <div class="pay-table-pagination d-flex justify-start"
             style="position: absolute; left: 0; bottom: 0"
             v-if="buildings.length"
        >
          <div class="pa-3 d-flex align-center justify-start">
            <v-btn text depressed icon class="mr-1"
                   @click.stop="pageStartOrEnd(true)"
                   :disabled="items_per_page > buildings.length || page === 1"
            >
              <v-icon>mdi-chevron-double-left</v-icon>
            </v-btn>
            <v-btn text depressed icon
                   @click.stop="changePage(true)"
                   :disabled="page === 1"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="mx-2">
              <v-chip>
                {{ `Сторінка: ${page} із ${pages}` }}
              </v-chip>
            </div>
            <v-menu top :close-on-click="true" :close-on-content-click="true">
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" class="mr-2">
                  {{ `${items_per_page} на стор.` }}
                </v-chip>
              </template>
              <v-list>
                <v-list-item
                    v-for="(item, index) in items_per_page_list"
                    :key="index"
                    @click="setItemsPerPage(item)"
                >
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn text depressed icon class="mr-1"
                   @click.stop="changePage(false)"
                   :disabled="items_per_page > buildings.length || page === pages"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn text depressed icon
                   @click.stop="pageStartOrEnd(false)"
                   :disabled="page === pages"
            >
              <v-icon>mdi-chevron-double-right</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {FETCH_BUILDINGS} from "@/store/actions/building";
import {mapGetters, mapActions} from 'vuex'
import {getBuildingIcon, cityTypesShort, streetTypesShort, streetTypes, buildingTypes} from "@/utils/icons";

export default {
  name: "Buildings",
  components: {
    MainModal: () => import("@/components/modal/MainModal"),
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    YouTubeButton: () => import("@/components/YouTubeButton")
  },
  data: () => ({
    cityId: null,
    streetId: null,
    pages: 0,
    items_per_page: 10,
    page: 0,
    items_per_page_list: [10, 12, 14, 16, 18, 20, 25, 50],
    table_view: false,
    buildingHeader: [
      {text: '', value: 'icon', width: 26},
      {text: 'Нас. пункт', value: 'city_name'},
      {text: 'Вулиця', value: 'street_name'},
      {text: '№ буд.', value: 'build_number'},
      {text: 'Тип буд.', value: 'building_type'},
      {text: 'К-сть кв.', value: 'flats'},
      {text: 'Дата створення', value: 'create_date'},
      {text: 'Автор', value: 'owner_name'},
    ],
    cityTypesShort,
    streetTypesShort,
    buildingTypes,
    selected_building: {},
    show_building_dialog: false,
  }),
  methods: {
    ...mapActions({
      fetchBuildings: FETCH_BUILDINGS
    }),
    saveBuildingsTableViewToLocalStorage() {
      if (localStorage.getItem('buildings_view_table_mode') === null) {
        localStorage.setItem('buildings_view_table_mode', this.table_view)
      } else {
        localStorage.removeItem('buildings_view_table_mode')
        localStorage.setItem('buildings_view_table_mode', this.table_view)
      }
    },
    buildingUpdateItemModal() {
      this.show_building_dialog = false
      this.selected_building = {}
    },
    openBuildingCreateDialog() {
      this.selected_building = {}
      this.show_building_dialog = true
    },
    openBuildingDialog(row) {
      this.$router.push({name: 'BuildView', params: {'id': row.id}})
    },
    changePage(left) {
      if (left) {
        this.page = this.page === 1 ? 1 : this.page - 1
      } else {
        this.page = this.page === this.pages ? this.pages : this.page + 1
      }
    },
    setPageSplitter() {
      this.pages = Math.ceil(this.buildings.length / this.items_per_page)
      this.page = this.pages ? 1 : 0

      if (this.pages * this.items_per_page - this.items_per_page > this.buildings.length) {
        this.pages -= 1
      }
    },
    setItemsPerPage(per_page) {
      this.items_per_page = per_page
      this.setPageSplitter()
    },
    pageStartOrEnd(start) {
      if (start) {
        this.page = 1
      } else {
        this.page = this.pages
      }
    },
    getMinWidth() {
      const width = {
        'xs': 'auto',
        'sm': 'auto',
        'md': '230',
        'lg': '250',
        'xl': '250'
      }
      return width[this.$vuetify.breakpoint.name]
    },
    getBuildingIcon,
    cityChange(payload) {
      this.$nextTick(() => {
        const cityId = (payload || {}).value || null

        const filter = {}
        if (cityId) filter['city_id'] = cityId
        if (this.streetId) filter['street_id'] = this.streetId
        this.fetchBuildings(filter)
            .then(() => {
              this.pages = 0
              this.page = 0
              this.setPageSplitter()
            })
      })
    },
    streetChange(payload) {
      this.$nextTick(() => {
        const streetId = (payload || {}).value || null

        const filter = {}
        if (this.cityId) filter['city_id'] = this.cityId
        if (streetId) filter['street_id'] = streetId

        this.fetchBuildings(filter)
            .then(() => {
              this.pages = 0
              this.page = 0
              this.setPageSplitter()
            })
      })

    },
  },
  computed: {
    streetTypes() {
      return streetTypes
    },
    ...mapGetters({
      buildings: 'getBuildings'
    }),
    filtered_items() {
      return this.buildings.slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
    }
  },
  created() {
    const buildings_view_table_mode = localStorage.getItem('buildings_view_table_mode')
    if (buildings_view_table_mode !== null) {
      this.table_view = buildings_view_table_mode
    }

    this.fetchBuildings()
        .then(() => {
          this.pages = 0
          this.page = 0
          this.setPageSplitter()
        })
  },
  beforeDestroy() {
    this.saveBuildingsTableViewToLocalStorage()
  }
}
</script>

<style scoped lang="scss">
.building-card {
  position: relative;
  overflow: hidden;

  .background {
    position: absolute;
    width: 60%;
    height: 150%;
    border-radius: 50% !important;
    transform: translateY(-50%);
  }

  .bg-1 {
    background: #f7f7f7;
    left: 71%;
    top: 50%;
    z-index: 8;
  }

  .bg-2 {
    background: #ffffff;
    left: 92%;
    top: 50%;
    z-index: 12;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .card-address {
    font-size: 1.1rem !important;
  }
}
</style>